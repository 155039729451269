.detail-header {
  color: #FFF;
  background: #1677ff;
  font-size: 14px;
  font-weight: 600;
  width:100%;
  height: 30px;
}

.detail-budget {
  color: #333;
  background: #f6f6f6;
  margin: 20px 0;
  padding: 35px 15px 10px 15px;
  font-size: 14px;
  position: relative;
}

.detail-budget-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  width: 100%;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 10px;
  background: #1677ff;
}
.detail-budget-card {
  flex-wrap: wrap;

}
