.header-title {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.header-back {
  position: absolute;
  top: 0;
  left: 15px;
  right: auto;
  bottom: 0;
  font-size: 20px;
}

.header {
  user-select: none;
  background: #1778ff;
  color:#fff;
  position: relative;
  height: 50px;
}
