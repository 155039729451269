.status-tag {
  display: flex;
  height: 20px;
  padding: 12px;
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 4px;
}
.status-tag-active{
  background: #f4ecdd;
  color: #f39532;
}
.status-tag-signup{
  background: #eadef3;
  color: #864dce;
}
.status-tag-await{
  background: #dde6f4;
  color: #4e78f4;
}
.status-tag-settled{
  background: #ddf2de;
  color: #28bc45;
}
